<template>
  <div class="noticias" style="height: calc(100% - 40px)">
    <template v-if="noticias">
      <h4 v-if="noticias.diarias" class="mx-2 text-secondary">
        Noticias del día
      </h4>
      <div class="cartelera">
        <router-link
          v-for="(noticia, i) in noticias.diarias"
          :key="i + noticia.titulo"
          :to="noticia.uuid ? '/noticias/mostrar/' + noticia.uuid : ''"
          :class="'card card-' + noticia.tipo"
        >
          <h4 class="card-title">{{ noticia.titulo }}</h4>
          <div class="noticia-content" v-html="noticia.contenido"></div>
        </router-link>
      </div>
      <h4 class="mx-2 text-secondary">Últimas noticias importantes</h4>
      <div class="cartelera">
        <router-link
          :to="'/noticias/mostrar/' + noticia.uuid"
          v-for="(noticia, i) in noticias.ultimas"
          :key="i + noticia.titulo"
          :class="'card card-' + noticia.tipo"
        >
          <h4 class="card-title">{{ noticia.titulo }}</h4>
          <p>Presione para ver la noticia</p>
        </router-link>
      </div>
      <h4 class="mx-2 text-secondary">Información de interés</h4>
      <div class="cartelera">
        <router-link
          :to="'/noticias/mostrar/' + noticia.uuid"
          v-for="(noticia, i) in noticias.interes"
          :key="i + noticia.titulo"
          :class="'card card-' + noticia.tipo"
        >
          <h4 class="card-title">{{ noticia.titulo }}</h4>
          <p>Presione para ver la noticia</p>
        </router-link>
      </div>
<!--      <div class="card">-->
<!--        <p>-->
<!--          Para cualquier consulta enviar mail a-->
<!--          <a class="text-primary" href="mailto:rrhh@adc.net.ar"-->
<!--            >rrhh@adc.net.ar</a-->
<!--          >-->
<!--          o contactarse por-->
<!--          <a class="text-primary" :href="whatsappLink">whatsapp</a>-->
<!--        </p>-->
<!--      </div>-->
    </template>
    <Modal v-if="procedimientoSinFirmar">
      <div slot="modal-body" style="min-width: 80vw">
        <h3 class="card-title">Nuevo procedimiento</h3>
        <h3 class="card-title text-center mb-2">
          {{ procedimientoSinFirmar.titulo }}
        </h3>
        <div
          class="recibo-preview"
          v-if="procedimientoSinFirmar.archivo"
        >
            <div class="commands-pdf">
                <div class="zoom-command">
                  <button type="button" id="zoom-minus" @click="width -= 50">
                    <icon feather="minus"/>
                  </button>
                  <b style="margin: auto 5px">ZOOM</b>
                  <button type="button" id="zoom-plus" @click="width += 50">
                    <icon feather="plus"/>
                  </button>
                </div>
                <button type="button" class="print-command" id="print-button" @click="imprimir">
                  <b style="margin: auto 5px">IMPRIMIR</b>
                  <icon feather="printer" />
                </button>
            </div>
          <div class="pdf-viewer">
            <pdf
              style="margin: 10px auto"
              v-for="i in numPages"
              :key="i"
              ref="pdf"
              :src="pdfProcedimiento"
              :rotate="rotate"
              :page="i"
              :style="'width:calc(70% + ' + width + 'px );'"
            ></pdf>
          </div>
        </div>
        <div
          style="padding: 0 5px"
          v-html="procedimientoSinFirmar.contenido"
          v-else
        ></div>
        <div class="w-100 d-flex" style="flex-direction: column">
          <button
            class="btn btn-primary my-2 btn-align-right"
            @click="marcarComoLeido"
          >
            Marcar como leído
          </button>
        </div>
      </div>
    </Modal>
<!--    <a :href="whatsappLink" class="whatsapp-floating-button" target="_blank">-->
<!--      <img src="@/assets/images/whatsapp.svg" />-->
<!--    </a>-->
  </div>
</template>
<script>
import { NoticiasServices } from "@/modules/noticias/services/NoticiasServices";
import { ProcedimientosServices } from "../modules/procedimientos/services/ProcedimientosServices";
import Modal from "../components/elements/Modal.vue";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
export default {
  name: "CarteleraDense",
  components: { Modal, pdf },
  data() {
    return {
      noticias: null,
      procedimientoSinFirmar: null,
      pdfProcedimiento: null,
      loadedRatio: 0,
      page: 1,
      width: 0,
      numPages: 0,
      rotate: 0,
    };
  },
  created() {
    ProcedimientosServices.api.getProcedimientosSinFirmar().then((response) => {
      this.procedimientoSinFirmar = response.procedimiento;
      if (this.procedimientoSinFirmar.archivo) {
        this.pdfProcedimiento = pdf.createLoadingTask(
          "data:application/pdf;base64," +
            this.procedimientoSinFirmar.contenido_archivo
        );
        this.pdfProcedimiento.promise.then((loadedPDF) => {
          this.numPages = loadedPDF.numPages;
        });
      }
    });
    NoticiasServices.api.getCartelera().then((response) => {
      this.noticias = response;
    });
  },
  computed: {
    whatsappLink() {
      if (window.screen.width >= 850) {
        return "http://web.whatsapp.com/send?phone=+5492954544947&abid=+5492954544947";
      } else {
        return "whatsapp://send?phone=+5492954544947&abid=+5492954544947";
      }
    }
  },
  methods: {
    marcarComoLeido() {
      ProcedimientosServices.api
        .marcarComoLeido(this.procedimientoSinFirmar.id)
        .then(() => {
          this.procedimientoSinFirmar = null;
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    },
    async imprimir(){
      const base64Response = await fetch("data:application/pdf;base64," + this.procedimientoSinFirmar.contenido_archivo);
      const blob = await base64Response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.target = "_blank";
      fileLink.style.display = "none";
      fileLink.click();
    }
  },
};
</script>
<style>
.whatsapp-floating-button {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 10px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.whatsapp-floating-button > img {
  margin-top: 7.5px;
  max-height: 30px;
}
</style>
